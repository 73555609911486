/* You can add global styles to this file, and also import other style files */

body {
  padding-top: 71px;
}

blockquote {
  background: #f0f0f0;
  padding: 0.5em;
  border-radius: 0.3em;
}
